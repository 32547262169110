import request from '@/utils/requestV2'
const qs = require('qs')

// 手动创建工单
export function createPlan (data) {
  return request({
    url: '/ooh-msp/v2/plan/createplan',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取resourceId素材和回传照片附件信息
export function getAttachInfoListByResourceId (data) {
  return request({
    url: '/ooh-msp/v2/plan/getattachinfolist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取工单详情
export function getPlanDetailPage (data) {
  return request({
    url: '/ooh-msp/v2/plan/getplandetailpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取工单列表
export function getPlanPage (data) {
  return request({
    url: '/ooh-msp/v2/plan/getplanpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 工单任务状态分布
export function getPlanTaskStatusStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/getplantaskstatusstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 工单任务类型分布
export function getPlanTaskTypeStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/getplantasktypestatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取工单作业时间数量和站点数量
export function getactiondatequantity (data) {
  return request({
    url: '/ooh-msp/v2/plan/getactiondatequantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取actionDate区间有作业线路和站点
export function getassetdomainlist (data) {
  return request({
    url: '/ooh-msp/v2/plan/getassetdomainlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 工单任务类型和类型下状态分布
export function gettasktypestatusstatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/gettasktypestatusstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取供应商作业任务类型统计
export function getTaskitemTypeStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/gettaskitemtypestatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 工单任务费用明细
export function getAmountStat (data) {
  return request({
    url: '/ooh-msp/v2/plan/getamountstat',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 质检验收确认明细
export function getPlanTasktypeFeeStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/getplantasktypefeestatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取上刊、下刊和填充任务关联制作任务
export function getRelatedTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/plan/getrelatedtaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取工单列表--按订单档期分组
export function getAdPlanPage (data) {
  return request({
    url: '/ooh-msp/v2/plan/getadplanpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 客户品牌工单任务费用明细
export function getAdAmountStat (data) {
  return request({
    url: '/ooh-msp/v2/plan/getadamountstat',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 客户品牌工单任务类型分布
export function getAdTasktypeStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/getadtasktypestatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 客户品牌工单任务状态分布
export function getTaskAdStatistic (data) {
  return request({
    url: '/ooh-msp/v2/plan/gettaskadstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询上刊任务对应制作任务集合
export function getProduceTaskList (data) {
  return request({
    url: '/ooh-msp/v2/plan/getProduceTaskList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 分页获取质检列表
 * @param {Object} data 参数
 * @returns
 */
export function getQualityTaskitemPage (data) {
  return request.post('/ooh-msp/v2/plan/getQualityTaskitemPage', qs.stringify(data))
}

/**
 * 分页获取质检-对账列表
 * @param {Object} data 参数
 * @returns
 */
export function getAccountTaskitemPage (data) {
  return request.post('/ooh-msp/v2/plan/getAccountTaskitemPage', qs.stringify(data))
}

/**
 * 获取同一主任务下，同一单品任务集合
 * @param {Object} data 参数
 * @returns
 */
export function getSkuPlanDetailList (data) {
  return request.post('/ooh-msp/v2/plan/getSkuPlanDetailList', qs.stringify(data))
}
