<template>
  <div>
    <Modal fullscreen v-model="fastCheckModal" footer-hide>
      <p slot="header" class="text-center">便捷质检</p>
      <div v-if="list.length === 0" class="text-center">
        <h2>暂无数据</h2>
      </div>
      <div v-else>
        <div class="m-b-10">
          <Button size="small" type="primary" class="m-r-5" @click="onSelectAll(selectedItems.length < total)">{{
            (selectedItems.length >= total) ? '取消全选' : '全选' }}</Button>

          <Button size="small" type="warning" class="m-r-5" @click="onRetake">重拍</Button>
          <Button size="small" type="success" @click="onConfirmCheck">确认质检</Button>
        </div>
        <div style="overflow-y: auto; overflow-x: hidden;">
          <div class="m-b-10">
            <span>当前总共<span class="p-r-5 p-l-5">{{ total }}</span>项</span><span v-show="selectedItems.length">，已选中<span
                class="p-r-5 p-l-5">{{ selectedItems.length }}</span>项</span>
          </div>
          <Card class="m-b-10 m-r-10" v-for="item in showList" :key="'task_' + item.id">
            <Row :gutter="8" class="m-b-10">
              <i-col span="8">
                <Row>
                  <i-col span="4" class="text-center" style="position:static;">
                    <div style="position:relative;top:45%;">
                      <Checkbox v-model="item._checked" @on-change="onSelectItem(item)" />
                    </div>
                  </i-col>
                  <i-col span="20">
                    <div class="text-center m-b-10">
                      <span>任务详情</span>
                    </div>
                    <Row :gutter="8" class="m-b-5">
                      <i-col span="12">
                        <label class="title">任务类型</label><span>{{ item.typeName }}</span>
                      </i-col>
                      <i-col span="12">
                        <label class="title">用途</label><span>{{ item.adTypeName }}</span>
                      </i-col>
                    </Row>
                    <Row :gutter="8" class="m-b-5">
                      <i-col span="12">
                        <label class="title">资源编号</label><span>{{ item.resourceCode }}</span>
                      </i-col>
                      <i-col span="12">
                        <label class="title">所在位置</label><span>{{ item.positionName }}</span>
                      </i-col>
                    </Row>
                    <Row :gutter="8" class="m-b-5">
                      <i-col span="12">
                        <label class="title">当前状态</label><span>{{ item.statusName }}</span>
                      </i-col>
                      <i-col span="12">
                        <label class="title">作业单位</label><span>{{ item.taskitemLabor.companyName || '/' }}</span>
                      </i-col>
                    </Row>
                    <Row :gutter="8" class="m-b-5">
                      <i-col span="12">
                        <label class="title">作业小组</label><span>{{ item.taskitemLabor.workgroupName || '/' }}</span>
                      </i-col>
                      <i-col span="12">
                        <label class="title">完成时间</label><span>{{ item.finishTime || '/' }}</span>
                      </i-col>
                    </Row>
                    <Row :gutter="8" class="m-b-5">
                      <i-col span="24">
                        <label class="title">备注</label><span>{{ item.remark || '/' }}</span>
                      </i-col>
                    </Row>
                    <template v-if="item.taskitemCharges && item.taskitemCharges.length">
                      <Row :gutter="8" v-for="bill in item.taskitemCharges" :key="'bill_' + bill.id">
                        <i-col span="12">
                          <label class="title">计费类型</label><span class="m-r-10">{{ bill.costTypeName }}</span><span
                            class="text-green">{{ formartMoney(bill.confirmCost) }}</span>
                        </i-col>
                        <i-col span="12">
                          <label class="title">计费数量</label><span class="m-r-5">{{ bill.confirmChargeQuantity
                          }}</span><span>{{ bill.confirmChargeTypeName }}</span>
                        </i-col>
                      </Row>
                    </template>
                    <Row class="m-t-10" v-if="item.chargeStatus === 1 && isAuth('inspection_index_fee')">
                      <i-col span="24">
                        <a href="javascript:void(0)" @click="onAdjustCost(item)">调整计费</a>
                      </i-col>
                    </Row>
                  </i-col>
                </Row>
              </i-col>
              <i-col span="8" class="text-center">
                <div class="text-center m-b-10">
                  <span>任务素材</span>
                </div>
                <div v-if="item.taskitemFiles.length === 0" class="text-center">
                  <span>暂无画面</span>
                </div>
                <Carousel v-else dots="outside" loop>
                  <CarouselItem v-for="file in item.taskitemFiles" :key="'file_' + file.id">
                    <a v-if="file.mimeType.includes('video') || file.mimeType.includes('audio')" :href="file.fileKey"
                      target="_blank">
                      <video :src="file.fileKey" width="600">
                        您的浏览器不支持 video 标签。
                      </video>
                    </a>
                    <a v-else :href="file.fileKey" target="_blank">
                      <img :src="file.fileKey + '?x-oss-process=image/resize,w_600'" />
                    </a>
                  </CarouselItem>
                </Carousel>
              </i-col>
              <i-col span="8" class="text-center">
                <div class="text-center m-b-10">
                  <span>反馈照片</span>
                </div>
                <div v-if="item.taskitemAttachs.length === 0" class="text-center">
                  <span>暂无画面</span>
                </div>
                <Carousel v-else dots="outside" loop>
                  <CarouselItem v-for="feedback in item.taskitemAttachs" :key="'feedback_' + feedback.id">
                    <a v-if="feedback.mimeType.includes('video') || feedback.mimeType.includes('audio')"
                      :href="feedback.fileKey" target="_blank">
                      <video :src="feedback.fileKey" width="600">
                        您的浏览器不支持 video 标签。
                      </video>
                    </a>
                    <a v-else :href="feedback.fileKey" target="_blank">
                      <img :src="feedback.fileKey + '?x-oss-process=image/resize,w_600'" />
                    </a>
                  </CarouselItem>
                </Carousel>
              </i-col>
            </Row>
          </Card>
          <div v-if="list.length < total" class="text-center">
            <Button type="primary" size="small" @click="onLoadingMore">加载更多</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { toMoney } from '@/utils/wnumb_own'
import { getPlanDetailPage } from '@/api/msp/plan'
export default {
  data () {
    return {
      fastCheckModal: false,
      query: {
        pageNumber: 1,
        pageSize: 5,
        domainId: undefined,
        actionDate: '',
        qualityTesting: 1,
        taskIds: '',
        taskitemType: undefined
      },
      total: 0,
      list: [],
      showList: [],
      selectedItems: [],
      isChecked: false // 是否已经质检
    }
  },
  computed: {
    planDetailQuery () {
      return this.$store.state.qualityTesting.planDetailQuery
    },
    showType () {
      return this.$store.state.qualityTesting.showType
    }

  },
  methods: {
    init () {
      this.selectedItems = []
      this.list = []
      this.showList = []
      this.total = 0
      this.query.pageNumber = 1
      this.query.domainId = this.planDetailQuery.domainId
      this.query.actionDate = this.planDetailQuery.actionDate
      this.query.taskitemType = this.planDetailQuery.taskitemType
      this.query.taskIds = this.showType === 2 ? this.planDetailQuery.taskIds : ''
      this.fastCheckModal = true
      this.getListData()
    },
    getListData () {
      getPlanDetailPage(this.query).then((res) => {
        if (res && !res.errcode) {
          res.list.forEach(item => {
            item._checked = false
          })
          this.list = this.showList.concat(res.list)
          this.showList = cloneDeep(this.list)
          this.total = res.totalRow
          if (this.total === 0) {
            this.$Message.info({
              background: true,
              duration: 3,
              content: '当前暂无数据，窗口将在3秒后关闭'
            })
            // this.$Notice.warning({ desc: '当前暂无数据，窗口将在3秒后关闭' })
            setTimeout(() => {
              this.fastCheckModal = false
            }, 3000)
          }
        }
      })
    },
    onLoadingMore () {
      this.query.pageNumber++
      this.getListData()
    },
    formartMoney (number) {
      return toMoney(number)
    },
    onSelectAll (cancel = false) {
      this.list.forEach(item => {
        item._checked = cancel
      })
      this.showList = cloneDeep(this.list)
      this.selectedItems = this.showList.filter(x => x._checked)
    },
    /**
     * 单选操作
     * @param {Object} params
     */
    onSelectItem (params) {
      if (this.selectedItems.some(x => x.id === params.id)) {
        // 如果选中项中存在，则移除
        this.selectedItems.splice(this.selectedItems.findIndex(s => s.id === params.id), 1)
      } else {
        // 如果不存在，则加入
        this.selectedItems.push(params)
      }
    },
    onAdjustCost (data) {
      const postData = Object.assign(data, { isFastCheck: true })
      this.$emit('on-adjust-cost', postData)
    },
    onConfirmCheck () {
      const ids = this.selectedItems.map(x => x.id)
      if (ids.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一项。' })
        return false
      }
      this.$emit('on-confrim-fast-check', ids, true)
    },
    onRetake () {
      const ids = this.selectedItems.map(x => x.id)
      if (ids.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一项。' })
        return false
      }
      this.$emit('on-retake', ids, true)
    }
  }
}
</script>
